
import mixpanel from 'mixpanel-browser';
import Vue from 'vue';
export default Vue.extend({
  name: 'AuthConfirmEmail',
  computed: {
    provider(): any {
      return this.$altoleapAccounts.authProvider;
    },
    company(): any {
      return this.$altoleapAccounts.config.companyName;
    },
    logo(): any {
      return this.$altoleapAccounts.config.logo;
    },
    key() {
      return this.$route.params.key;
    }
  },
  methods: {
    reload: () => window.location.reload()
  },

  async created() {
    await this.provider.confirmEmail(this.key).then(() => {
      try {
        mixpanel.track('New User Email Verified');
      } catch (e) {
        console.warn(e);
      }
    });
  }
});
